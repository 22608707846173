import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonVariant } from 'components/Button';
import { Modal } from 'components/Modal';
import { PanelBody, PanelActions } from 'components/Panel';

interface ConfirmModalProps {
  /**
   * Modal title
   */
  title?: ReactNode;
  /**
   * Modal content
   */
  content?: ReactNode;
  /**
   * Confirm button label (Yes | Confirm)
   */
  confirmButtonLabel?: ReactNode;
  /**
   * Decline button label (No | Decline)
   */
  declineButtonLabel?: ReactNode;
  /**
   * Confirm button style variant
   */
  confirmButtonVariant?: ButtonVariant;
  /**
   * Flag to open/close modal
   */
  isOpen?: boolean;
  /**
   * Confirm handler which will be resolved after click on confirm button
   */
  onConfirm: VoidFunction;
  /**
   * Decline handler which will be resolved after click on decline button
   */
  onDecline?: VoidFunction;
  /**
   * Decline button is visible by default
   */
  declineHidden?: boolean;
}

/**
 * Renders modal with comfirm/decline functionality
 */
export const ConfirmModal: FC<ConfirmModalProps> = ({
  children,
  title,
  content,
  confirmButtonLabel,
  declineButtonLabel,
  confirmButtonVariant,
  isOpen,
  onDecline,
  onConfirm,
  declineHidden,
}) => {
  const { t } = useTranslation();
  const body = children || content;

  return (
    <Modal opened={isOpen} title={title}>
      {!!body && <PanelBody>{body}</PanelBody>}

      <PanelActions>
        {!declineHidden && (
          <Button className="mr-05" variant="text" onClick={onDecline}>
            {declineButtonLabel || t('cancel')}
          </Button>
        )}
        <Button autoFocus variant={confirmButtonVariant} onClick={onConfirm}>
          {confirmButtonLabel || t('Ok')}
        </Button>
      </PanelActions>
    </Modal>
  );
};
