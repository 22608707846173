import { ApiClient } from './apiClient';
import {
  QualificationListResponse,
  QualificationSaveRequest,
  QualificationUpdateRequest,
  NumberOfAssessedTopicsResponse,
  QualificationListRequest,
} from '../model/qualification';
import { APIv1 } from 'api/instance';

const BASE_URL = process.env.REACT_APP_API_URL + 'v1/qualification';

export class QualificationService {
  private readonly apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  async getQualifications({
    limit,
    offset,
    instituteId,
    userId,
    isInstituteAdmin,
  }: QualificationListRequest): Promise<QualificationListResponse> {
    const response = await APIv1.get<QualificationListResponse>(
      `${BASE_URL}/all?limit=${limit}&offset=${offset}&instituteId=${instituteId}&userId=${userId}&isInstituteAdmin=${isInstituteAdmin}`
    );
    return response.data;
  }

  async getQualificationById(id: string): Promise<QualificationListResponse> {
    try {
      const response = await APIv1.get<QualificationListResponse>(
        `${BASE_URL}/?id=${id}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }

  async postQualification(data: QualificationSaveRequest): Promise<any> {
    return await APIv1.post<QualificationSaveRequest, void>(
      `${BASE_URL}`,
      data
    );
  }

  async updateQualificationGeneralData(
    id: string,
    data: QualificationUpdateRequest
  ): Promise<any> {
    return await APIv1.put(`${BASE_URL}/updateGeneralData/?id=${id}`, data);
  }

  async updateQualification(
    id: string,
    data: QualificationUpdateRequest
  ): Promise<any> {
    return await APIv1.put(`${BASE_URL}/?id=${id}`, data);
  }

  async deleteQualification(id: string): Promise<any> {
    return await APIv1.delete<void>(`${BASE_URL}/?id=${id}`);
  }

  async setQualificationStatusAsVerified(
    qualification: QualificationUpdateRequest
  ): Promise<any> {
    return await APIv1.put(`${BASE_URL}/verify`, qualification);
  }

  async numberOfAssessedTopics(qualificationId: string): Promise<any> {
    return await APIv1.get<NumberOfAssessedTopicsResponse>(
      `${BASE_URL}/numberOfAssessedTopics?qualificationId=${qualificationId}`
    );
  }

  async createPdf(id: string): Promise<any> {
    return await APIv1.post<string, void>(
      `${BASE_URL}/createPdf`,
      { id },
      {
        responseType: 'blob',
      }
    );
  }
}
