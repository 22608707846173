import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { FC, useEffect } from 'react';

interface CustomOnChangePluginProps {
    onChange: (content: string) => void;
}

const CustomOnChangePlugin: FC<CustomOnChangePluginProps> = ({ onChange }) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        const removeUpdateListener = editor.registerUpdateListener(
            ({ editorState }) => {
                editorState.read(() => {
                    onChange(JSON.stringify(editor.getEditorState()));
                });
            }
        );
        return () => {
            removeUpdateListener();
        };
    }, [onChange, editor]);

    return null;
};

export default CustomOnChangePlugin;
