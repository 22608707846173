import React, { FC } from 'react';
import styles from './Label.module.scss';
import cn from 'classnames';

export type LabelSize =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'heading-sm'
  | 'heading-md'
  | 'heading-lg'
  | 'heading-xl'
  | 'heading-xxl'
  | 'heading-xs'
  | 'heading-xxs';

interface LabelProps extends React.ComponentPropsWithoutRef<'label'> {
  size?: LabelSize;
}

export const Label: FC<LabelProps> = ({ size = 'md', ...props }) => {
  const labelClassName = cn(styles.label, styles[size]);

  return (
    <div>
      <label className={labelClassName} {...props}>
        {props.content}
      </label>
    </div>
  );
};

export default Label;
