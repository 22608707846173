import classNames from 'classnames';
import styles from './Tab.module.scss';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

interface props {
  isActive?: boolean;
  className?: string;
  activeClassName?: string;
  children?: any;
  to: string;
  component?: string;
  disabled?: boolean;
}

export const Tab: FC<props> = ({
  isActive,
  className,
  children,
  to,
  disabled,
}) => {
  return (
    <NavLink
      activeClassName={styles.activeTab}
      className={classNames(className, styles.tab, {
        [styles.activeTab as string]: isActive,
        [styles.disabled as string]: disabled,
      })}
      to={to}
    >
      {children}
    </NavLink>
  );
};

export default Tab;
