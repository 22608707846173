import React from 'react';
import cn from 'classnames';
import styles from './Button.module.scss';

export type ButtonVariant =
  | 'danger'
  | 'primary'
  | 'secondary'
  | 'secondary-danger'
  | 'text'
  | 'text-danger';

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg';

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  size?: ButtonSize;
  variant?: ButtonVariant;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  size = 'md',
  variant = 'primary',
  disabled = false,
  children,
  className,
  ...props
}) => {
  const buttonClassName = cn(
    styles.button,
    styles[size],
    styles[variant],
    disabled && styles.disabled,
    className
  );
  return (
    <button className={buttonClassName} disabled={disabled} {...props}>
      <span className={styles.children}> {children} </span>
    </button>
  );
};

export default Button;
