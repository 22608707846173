import {
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  FORMAT_TEXT_COMMAND,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import Button from '../Button';
import styles from './TextArea.module.scss';

const CustomToolbarPlugin = () => {
  const [editor] = useLexicalComposerContext();

  function handleBoldToggle() {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
  }

  function handleRegularToggle() {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        selection.getNodes().forEach((node) => {
          if ($isTextNode(node)) {
            node.setFormat(0);
          }
        });
      }
    });
  }

  return (
    <div className={styles.toolbar}>
      <Button onClick={handleBoldToggle} type="button">
        BOLD
      </Button>
      <Button onClick={handleRegularToggle} type="button">
        REGULAR
      </Button>
    </div>
  );
};

export default CustomToolbarPlugin;
