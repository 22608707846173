import { normalizeResponse } from 'api/helpers/normalize-response';
import { APIv1 } from 'api/instance';
import { userSchema, usersListSchema } from './schemas';
import { Institute } from 'common-types/common';
import { User } from 'common-types/users';

export const readUser = (id: User['id']) =>
  APIv1.get(`/users/${id}`).then(normalizeResponse(userSchema));

export const searchUsers = (query: string, institute: Institute['id']) => {
  return APIv1.get(`/usersByInstituteId/${institute}?query=${query}`).then(
    normalizeResponse(usersListSchema)
  );
};

export const updateUser = (userId: User['id'], update) =>
  APIv1.patch(`/users/${userId}`, update).then(normalizeResponse(userSchema));
