import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { QualificationFormListResponse } from '../model/qualificationForm';
import { qualificationFormService } from '../services/services';

const initialState = {
  qualificationForm: null as QualificationFormListResponse | null,
  status: 'idle',
  error: null as string | null,
};

export const fetchQualificationForms = createAsyncThunk(
  'qualificationForms/fetch',
  ({ limit, offset }: { limit: number; offset: number }) => {
    return qualificationFormService.getQualificationForms({
      limit,
      offset,
    });
  }
);

export const fetchQualificationFormById = createAsyncThunk(
  'qualificationFormById/fetch',
  (id: string) => {
    return qualificationFormService
      .getQualificationFormsById(id)
      .then((response: any) => {
        if (response.error) {
          throw response;
        } else {
          return response;
        }
      });
  }
);

export const postQualificationForm = createAsyncThunk(
  'qualificationForm/post',
  (data: any) => {
    return qualificationFormService.postQualificationForm(data);
  }
);

export const putQualificationForm = createAsyncThunk(
  'qualificationForm/put',
  (data: any) => {
    return qualificationFormService.putQualificationForm(data);
  }
);

export const deleteQualificationForm = createAsyncThunk(
  'qualificationForm/delete',
  (id: string) => {
    return qualificationFormService.deleteQualificationForm(id);
  }
);

export const qualificationFormSlice = createSlice({
  name: 'qualificationForm',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQualificationForms.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchQualificationForms.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.qualificationForm = action.payload;
      })
      .addCase(fetchQualificationForms.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? null;
      })
      .addCase(fetchQualificationFormById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchQualificationFormById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.qualificationForm = action.payload;
      })
      .addCase(fetchQualificationFormById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? null;
      })

      .addCase(postQualificationForm.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postQualificationForm.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.qualificationForm = action.payload;
      })
      .addCase(postQualificationForm.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? null;
      })
      .addCase(putQualificationForm.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(putQualificationForm.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.qualificationForm = action.payload;
      })

      .addCase(putQualificationForm.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? null;
      })

      .addCase(deleteQualificationForm.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteQualificationForm.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.qualificationForm = action.payload;
      })
      .addCase(deleteQualificationForm.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message ?? null;
      });
  },
});

// export const {} = qualificationFormSlice.actions;

export default qualificationFormSlice.reducer;
