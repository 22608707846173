import { IconEmphasized, OverflowText, Text } from 'components';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { RootState } from 'v2/app/store';
import { Col, Row } from 'v2/components/atoms';
import { QualificationFormResponse } from 'v2/model/qualificationForm';

export const QualificationTitle: FC = () => {
  const { t } = useTranslation();

  const qualificationForms = useSelector(
    (state: RootState) => state.qualificationForm.qualificationForm
  );

  const qualifications = useSelector(
    (state: RootState) => state.qualification.qualifications
  );

  const { qualificationFormId } =
    useParams<{ qualificationFormId: string; qualificationId: string }>();

  const pageTitle = useMemo(() => {
    if (!qualificationForms) return t('qualification.qualificationTitle');

    let tempQualificationForms: any = qualificationForms;
    if (qualificationForms?.data)
      tempQualificationForms = qualificationForms.data;

    const qualificationForm = (
      tempQualificationForms as unknown as QualificationFormResponse[]
    )?.find((q) => q.id === qualificationFormId);

    return qualificationForm?.title || t('qualification.qualificationTitle');
  }, [qualificationFormId, qualificationForms, t]);

  const icon = useMemo(() => {
    if (!qualifications) return <IconEmphasized icon="delay" type="warning" />;

    const status = qualifications[0]?.status;

    switch (status) {
      case 'ongoing':
        return <IconEmphasized icon="delay" type="warning" />;
      case 'verified':
        return <IconEmphasized icon="star" type="warning" />;

      default:
        return <IconEmphasized icon="delay" type="warning" />;
    }
  }, [qualifications]);

  const trainee = useMemo(() => {
    if (qualifications && qualifications[0]?.trainee)
      return `${qualifications[0]?.trainee.givenName} ${qualifications[0]?.trainee.surname}`;

    return '';
  }, [qualifications]);

  return (
    <>
      <Row>
        <Col auto>{icon}</Col>
        <Col>
          <Text variant="heading-xl" className="mb-0">
            <OverflowText component="span" maxLines={1}>
              {pageTitle}
            </OverflowText>
            <Text>{trainee}</Text>
          </Text>
        </Col>
      </Row>
    </>
  );
};
