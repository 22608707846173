import { FC, useEffect } from 'react';
import cn from 'classnames';
import styles from './TextArea.module.scss';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import TooblbarPlugin from './ToolbarPlugin';
import OnChangePlugin from './OnChangePlugin';
import { useTranslation } from 'react-i18next';

interface Theme {
  paragraph?: string;
  text?: {
    bold?: string;
  };
}

interface Config {
  namespace: string;
  theme: Theme;
  onError: (error: Error) => void;
  editable: boolean;
  editorState?: string;
}

type LabelSize = 'sm' | 'md';

interface TextAreaProps {
  onChange?: (content: string) => void;
  editable?: boolean;
  placeholder?: string;
  value?: string;
  size?: LabelSize;
}

const theme: Theme = {
  paragraph: styles.editorParagraph,
  text: {
    bold: styles.editorBold,
  },
};

function onError(error: Error) {
  console.error(error);
}

export const TextArea: FC<TextAreaProps> = ({
  onChange,
  editable = true,
  placeholder,
  value,
  size = 'md',
}) => {
  const { t } = useTranslation();

  let initialConfig: Config = {
    namespace: 'MyEditor',
    theme,
    onError,
    editable,
  };

  if (value) {
    initialConfig = {
      namespace: 'MyEditor',
      theme,
      onError,
      editable,
      editorState: value,
    };
  }

  useEffect(() => {}, [value]);

  const contentEditableClassNames = cn(
    styles.contentEditable,
    !editable ?? styles.contentEditableDisabled,
    styles[size]
  );

  return (
    <div className={cn(editable && styles.textAreaWrapper)}>
      <LexicalComposer initialConfig={initialConfig}>
        {editable && (
          <>
            <TooblbarPlugin />
            {onChange && <OnChangePlugin onChange={onChange} />}
          </>
        )}
        <div className={styles.editorWrapper}>
          <RichTextPlugin
            contentEditable={
              <ContentEditable className={contentEditableClassNames} />
            }
            placeholder={
              <div className={styles.placeholder}>
                {placeholder || t('EnterSomeText')}
              </div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
        </div>
      </LexicalComposer>
    </div>
  );
};

export default TextArea;
