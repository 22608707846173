import React, { FC } from 'react';
import cn from 'classnames';
import styles from './TextField.module.scss';

export type TextFieldSize = 'xs' | 'sm' | 'md' | 'lg';

interface InputProps extends React.ComponentPropsWithoutRef<'input'> {
  readOnly?: boolean;
  fieldSize?: TextFieldSize;
  error?: string;
}

export const TextField: FC<InputProps> = ({
  readOnly = false,
  disabled,
  fieldSize = 'md',
  className,
  error,
  ...props
}) => {
  return (
    <div>
      <div
        className={cn(
          styles.inputBase,
          disabled && styles.disabled,
          error && styles.inputError,
          className
        )}
      >
        <input
          className={cn(styles.input, styles[fieldSize])}
          readOnly={readOnly}
          disabled={disabled}
          onClick={(e) => e.stopPropagation()}
          onWheel={(e) => (e.target as HTMLElement).blur()}
          {...props}
        />
      </div>
      {error && <div className={cn(styles.error)}>{error}</div>}
    </div>
  );
};

export default TextField;
