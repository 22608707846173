import { useProduct } from 'hooks/useProduct';
import { FC, memo, HTMLAttributes } from 'react';

import { LogoVariant, getLogoSrc } from './utils';
import { Link } from 'react-router-dom';
import { DASHBOARD } from 'scenes/routes.enum';

export interface LogoProps extends HTMLAttributes<HTMLImageElement> {
  /**
   * Variant of the logo
   */
  variant?: LogoVariant;
}

/**
 * Renders IMPedia logo in different versions
 */
export const Logo: FC<LogoProps> = memo(function Logo({ variant, ...props }) {
  const { productName, productType } = useProduct();

  return (
    <Link to={DASHBOARD}>
      <img
        src={getLogoSrc(productType, variant)}
        alt={productName}
        {...props}
      />
    </Link>
  );
});
