import { useRef, useEffect, FC, ChangeEvent } from 'react';
import classnames from 'classnames';
import styles from './RadioSwitch.module.scss';

interface RadioSwitchProps {
  id?: string;
  name?: string;
  options: {
    label: string;
    value: string | number | null;
  }[];
  size?: 'md' | 'sm';
  value?: string | number | readonly string[] | null;
  defaultValue?: number | string | readonly string[];
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const RadioSwitch: FC<RadioSwitchProps> = ({
  className,
  name,
  options,
  value = null,
  size = 'md',
  defaultValue = null,
  onChange,
  disabled,
  ...radioProps
}) => {
  const radioRefs = useRef<(HTMLInputElement | null)[]>([]);

  // Set tabIndex dynamically based on selected radio or first radio
  useEffect(() => {
    radioRefs.current.forEach((radio, index) => {
      if (radio) {
        radio.tabIndex =
          value === options[index].value || (!value && index === 0) ? 0 : -1;
      }
    });
  }, [value, options]);

  if (!options) return null;

  return (
    <div
      className={classnames(className, styles.container, {
        [styles[`size-${size}`]]: size,
      })}
      tabIndex={0}
      role="radiogroup"
      onKeyDown={(e) => {
        if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
          e.preventDefault();
          const currentIndex = options.findIndex(
            (option) => option.value === value
          );
          const nextIndex = (currentIndex + 1) % options.length;
          radioRefs.current[nextIndex]?.focus();
          radioRefs.current[nextIndex]?.click();
        } else if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
          e.preventDefault();
          const currentIndex = options.findIndex(
            (option) => option.value === value
          );
          const prevIndex =
            (currentIndex - 1 + options.length) % options.length;
          radioRefs.current[prevIndex]?.focus();
          radioRefs.current[prevIndex]?.click();
        }
      }}
    >
      {options.map((option, index) => {
        const id = `${name}-${option.value}`;
        const isChecked = value === option.value;
        const isFirst = index === 0 && !value;

        return (
          <label key={id} htmlFor={id} className={styles.label}>
            <input
              {...radioProps}
              className={styles.input}
              id={id}
              name={name}
              type="radio"
              value={option.value || ''}
              checked={isChecked}
              defaultChecked={defaultValue === option.value}
              onChange={onChange}
              disabled={disabled}
              tabIndex={isChecked || isFirst ? 0 : -1} // Make only selected or first radio tabbable
              ref={(el) => (radioRefs.current[index] = el)} // Save reference to radio button
            />
            <span className={styles.labelText}>{option.label}</span>
          </label>
        );
      })}
    </div>
  );
};
