import { FC } from 'react';
import classnames from 'classnames';

import styles from './RadioButton.module.scss';

interface RadioButtonProps extends React.ComponentPropsWithoutRef<'input'> {
  options: {
    label: string;
    value: string | number | null;
  }[];
  radioSize?: 'md' | 'sm';
}

const RadioButton: FC<RadioButtonProps> = ({
  className,
  name,
  options,
  radioSize = 'md',
  disabled,
  value,
  ...radioProps
}) => {
  if (!options) return null;

  return (
    <div
      className={classnames(className, styles.container, {
        [styles[`size-${radioSize}`] as string]: radioSize,
      })}
    >
      {options.map((option) => {
        const id = `${name}-${option.value}`;
        return (
          <label key={id} htmlFor={id} className={styles.label}>
            <input
              {...radioProps}
              className={styles.input}
              id={id}
              name={name}
              type="radio"
              value={option.value || ''}
              checked={option.value === value}
              disabled={disabled}
            />
            <span className={styles.labelText}>{option.label}</span>
          </label>
        );
      })}
    </div>
  );
};

export default RadioButton;
